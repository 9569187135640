import React, { memo } from 'react';
import '../../index.css';
import TwitterXImage from '../../assets/images/twitterx.webp';
import LinkedinImage from '../../assets/images/linkedin.webp';
import InstagramImage from '../../assets/images/instagram.webp';

const Footer = () => {
    return (
        <div className="flex flex-col md:flex-row md:items-center lg:p-8 pt-24 footer-background">
            {/* Left Section for Desktop */}
            <span className="hidden md:block lg:w-3/6 space-x-4 text-sm">
                © 2024 Lillup. All rights reserved.
            </span>

            {/* Links Section */}
            <div className="flex flex-col md:w-2/5 p-10 py-0 gap-6 text-sm font-bold leading-6 md:flex-row md:gap-4">
                <a href="#footer">Terms and Conditions</a>
                <a href="#footer">Privacy Policy</a>
                <a href="#footer">Contact</a>
            </div>

            {/* Social Media Icons */}
            <div className="flex justify-between p-10 md:pl-0 md:ml-auto space-x-4">
                <a
                    href="https://twitter.com/LillupWorkplace"
                    target="__blank"
                    rel="noopener noreferrer"
                    className="hover-scale">
                    <img
                        src={TwitterXImage}
                        alt="Twitter icon for Lillup Workplace"
                        width="32"
                        height="32"
                        loading="lazy"
                    />
                </a>
                <a
                    href="https://www.linkedin.com/company/lillup/"
                    target="__blank"
                    rel="noopener noreferrer"
                    className="hover-scale">
                    <img
                        src={LinkedinImage}
                        alt="LinkedIn icon for Lillup"
                        width="32"
                        height="32"
                        loading="lazy"
                    />
                </a>
                <a
                    href="https://www.instagram.com/lillup_ampersand/"
                    target="__blank"
                    rel="noopener noreferrer"
                    className="hover-scale">
                    <img
                        src={InstagramImage}
                        alt="Instagram icon for Lillup"
                        width="32"
                        height="32"
                        loading="lazy"
                    />
                </a>
            </div>

            {/* Footer Text for Mobile */}
            <span className="block md:hidden pl-10 pb-10 w-auto text-sm">
                © 2024 Lillup. All rights reserved.
            </span>
        </div>
    );
};

export default memo(Footer);
